import * as React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import '@webcomponents/webcomponentsjs/webcomponents-bundle'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
import SECFilings from '../components/SECFilings'
import { initializeEnvPaths } from '../utils/envPaths'

class SECFilingsWebComponent extends HTMLElement {
  constructor (props) {
    super(props)
    this.observer = new MutationObserver(() => this.update())
    this.observer.observe(this, {
      attributes: true
    })
    this.attachShadow({
      mode: 'open'
    })
  }

  connectedCallback () {
    this._innerHTML = this.innerHTML
    this.mount()
  }

  disconnectedCallback () {
    this.unmount()
    this.observer.disconnect()
  }

  update () {
    this.unmount()
    this.mount()
  }

  mount () {
    const props = {
      ...this.getProps(this.attributes),
      ...this.getEvents(),
      ...window.xprops,
      children: this.parseHtmlToReact(this.innerHTML)
    }

    initializeEnvPaths(props.env)

    const style = document.createElement('link')
    style.setAttribute(
      'href',
      `${this.fileDomain(props.env)}static/css/secFilingsWebComponent.css`
    )
    style.setAttribute('rel', 'stylesheet')
    render(<SECFilings {...props} />, this.shadowRoot)
    this.shadowRoot.append(style)
  }

  unmount () {
    unmountComponentAtNode(this)
  }

  parseHtmlToReact (html) {
    return html
  }

  fileDomain (env) {
    let fileDomain = ''
    switch (env) {
      case 'localhost':
        fileDomain = 'http://localhost:5500/'
        break
      case 'acceptance':
        fileDomain = 'https://commsec-ui.markitqa.com/'
        break
      default:
        fileDomain = 'https://commsec-ui.markitdigital.com/'
        break
    }
    return fileDomain
  }

  getProps (attributes) {
    return [...attributes]
      .filter((attr) => attr.name !== 'style')
      .map((attr) => this.convert(attr.name, attr.value))
      .reduce(
        (props, prop) => ({
          ...props,
          [prop.name]: prop.value
        }),
        {}
      )
  }

  getEvents () {
    return Object.values(this.attributes)
      .filter((key) => /on([a-z].*)/.exec(key.name))
      .reduce(
        (events, ev) => ({
          ...events,
          [ev.name]: (args) =>
            this.dispatchEvent(
              new CustomEvent(ev.name, {
                ...args
              })
            )
        }),
        {}
      )
  }

  convert (attrName, attrValue) {
    let value = attrValue
    if (attrValue === 'true' || attrValue === 'false') {
      value = attrValue === 'true'
    } else if (!isNaN(attrValue) && attrValue !== '') value = +attrValue
    else if (/^{.*}/.exec(attrValue)) value = JSON.parse(attrValue)
    return {
      name: attrName,
      value: value
    }
  }
}

customElements.define('sec-filings-webcomponent', SECFilingsWebComponent)
